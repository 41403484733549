import { useState } from "react";
import { API_KEY, API_URL, FORGOT_PASSSWORD_PATH } from "../config/EndleConfig";
import axios from "axios";

const ForgotPassword = () => {

    const [username, setUsername] = useState('');
    const [message, setMessage] = useState();
    const [isPending, setIsPending] = useState(false);

    const submitHandler = (event) => {
        event.preventDefault();
        if(username.trim() === '') {
            setMessage('Username is required');
        }
        else if(username.length <3 || username.length > 24) { setMessage('Valid usernames are between 3 and 24 characters'); }
        else {
            setIsPending(true);
            setMessage();
            const forgot_url = API_URL + FORGOT_PASSSWORD_PATH;
            const request_config = {
                headers: { 'x-api-key':API_KEY },
                params: { 'u': username }
            };
            axios.get(forgot_url, request_config).then( response => {
                setIsPending(false);
                setMessage("Please check your email for a password reset link.");
            }).catch( error => {
                setIsPending(false);
                setMessage("Something went wrong, please try again later");
            })
        }
    }

    return ( 
        <div className="forgot-password">
            <form onSubmit={submitHandler} autoComplete="off">
            <h2>Forgot Password</h2>
            <section>
                <div className="two-column-grid grid med-2-grid">
                <div>username:</div>
                <div>
                    <input name="username" type="text" value={username} onChange={ 
                    (event) => {
                        setMessage('');
                        setUsername(event.target.value);
                    }} />
                </div>
                </div>
                {isPending && <div className="loading">LOADING ...</div> }
                {!isPending && <div className="button orange-background" onClick={ submitHandler }>Reset Password</div>}
            </section>
            </form>
            { message && <p className="message">{ message }</p>}
        </div> 
    );
}
 
export default ForgotPassword;