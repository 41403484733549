import platinum_coin from "../assets/images/platinum_coin.png";
import electrum_coin from "../assets/images/electrum_coin.png";
import gold_coin from "../assets/images/gold_coin.png";
import silver_coin from "../assets/images/silver_coin.png";
import copper_coin from "../assets/images/copper_coin.png";

import CancelBtn from "./buttons/CancelBtn";
import EditBtn from "./buttons/EditButton";
import { Fragment, useState } from "react";
import SaveBtn from "./buttons/SaveButton";
import { DM_ROLE } from "../config/EndleConfig"

const Coins = ({coins, user, handler}) => {
    const [isEdit, setIsEdit] = useState(false);
    const [original, setOriginal] = useState(coins);

    const toggleEdit = () => { setIsEdit(!isEdit); }
    const save = () => { 
        if(coins.pp === '') coins.pp = 0;
        if(coins.ep === '') coins.ep = 0;
        if(coins.gp === '') coins.gp = 0;
        if(coins.sp === '') coins.sp = 0;
        if(coins.cp === '') coins.cp = 0;
        handler.submit();
        setOriginal({...coins});
        toggleEdit();
    }

    const cancel = () => {
        coins.pp = original.pp;
        coins.ep = original.ep;
        coins.gp = original.gp;
        coins.sp = original.sp;
        coins.cp = original.cp;
        handler.updateTreasure(coins);
        toggleEdit();
    }

    return (
        <section>
            <div className="header-flex">
            <h2>currency</h2>
            { user.role ===  DM_ROLE && !isEdit && (<div><EditBtn handler={toggleEdit} /></div>)}
            { user.role === DM_ROLE && isEdit && (
                <div>
                    <CancelBtn handler={cancel} />
                    <SaveBtn handler={save} />
                </div>
            )}
            </div>
            
            <div className="coins-flex-box">
                <div>
                    <img src={platinum_coin} alt='coin'/>
                    { !isEdit && <p className="value">{coins.pp}</p> }
                    { isEdit && (<div><input type='number' 
                            value={coins.pp} 
                            onChange={(e)=>{ 
                                coins.pp = (e.target.value !== ''? parseInt(e.target.value): '');
                                handler.updateTreasure(coins);
                            }} 
                        /></div>) }
                    <p>plates (pp)</p>
                </div>
                <div>
                    <img src={electrum_coin} alt='coin'/>
                    { !isEdit && <p className="value">{coins.ep}</p> }
                    { isEdit && (<div><input type='number' 
                            value={coins.ep} 
                            onChange={(e)=>{ 
                                coins.ep = (e.target.value !== ''? parseInt(e.target.value): '');
                                handler.updateTreasure(coins);
                            }} 
                        /></div>) }
                    <p>tins (ep)</p>
                </div>
                <div>
                    <img src={gold_coin} alt='coin'/>
                    { !isEdit && <p className="value">{coins.gp}</p> }
                    { isEdit && (<div><input type='number' 
                            value={coins.gp} 
                            onChange={(e)=>{ 
                                coins.gp = (e.target.value !== ''? parseInt(e.target.value): '');
                                handler.updateTreasure(coins);
                            }} 
                        /></div>) }
                    <p>crowns (gp)</p>
                </div>
                <div>
                    <img src={silver_coin} alt='coin'/>
                    { !isEdit && <p className="value">{coins.sp}</p> }
                    { isEdit && (<div><input type='number' 
                            value={coins.sp} 
                            onChange={(e)=>{ 
                                coins.sp = (e.target.value !== ''? parseInt(e.target.value): '');
                                handler.updateTreasure(coins);
                            }} 
                        /></div>) }
                    <p>shields (sp)</p>
                </div>
                <div>
                    <img src={copper_coin} alt='coin'/>
                    { !isEdit && <p className="value">{coins.cp}</p> }
                    { isEdit && (<div><input type='number' 
                            value={coins.cp} 
                            onChange={(e)=>{ 
                                coins.cp = (e.target.value !== ''? parseInt(e.target.value): '');
                                handler.updateTreasure(coins);
                            }} 
                        /></div>) }
                    <p>caps (cp)</p>
                </div>
            </div>
        </section>
     );
}
 
export default Coins;