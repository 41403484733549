import valley_banner from "../assets/banners/valley-view.jpg";

const Welcome = () => {
    return ( 
        <div className="welcome">
            <div className="banner" style={{ backgroundImage: `url(${valley_banner})` }}>
            </div>
            <h1>Endleland</h1>
            <p>Follow along with the tales of Endleland.  The first tale is The Tapestry.  Season 1 will be available shortly.</p>
        </div>
     );
}
 
export default Welcome;