import React from "react";
import { getToken } from "../service/AuthService";
import { Navigate, Outlet } from "react-router-dom";
import Navbar from "../layouts/Navbar";
import ScrollToTop from "../components/ScrollToTop";

const MemberRoute = ({ redirect_path = '/login' }) => {
    const auth = getToken();
    if(!auth) return <Navigate to={ redirect_path } replace />
    return (
        <>
            <Navbar />
            <div className="App">
                <ScrollToTop />
                <div className="content">
                    <Outlet />
                </div>
            </div>
        </>
        
    );
}

export default MemberRoute;