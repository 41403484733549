import { Outlet } from "react-router-dom";
import GlyphNav from "../layouts/GlyphNav";
import ScrollToTop from "../components/ScrollToTop";

const GlyphRoute = () => {
    return ( 
        <>
            <GlyphNav />
            <ScrollToTop />
            <div className="Glyph">
                <Outlet />
            </div>
        </>
     );
}
 
export default GlyphRoute;