import { useState } from "react";
import { useParams } from "react-router-dom";
import { API_KEY, API_URL, FORGOT_PASSSWORD_PATH } from "../config/EndleConfig";
import { setUserSession } from "../service/AuthService";
import axios from "axios";

const ResetPassword = () => {
    const {username, token} = useParams();
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState();
    const [isPending, setIsPending] = useState();
    const [isResetDone, setIsResetedDone] = useState(false); 
    
    const submitHandler = (event) => {
        event.preventDefault();
        if(username.trim() === '' || password.trim() === '') { setMessage('All fields are required'); }
        else if(password.length <8 || password.length > 32) { setMessage('valid passwords are between 8 and 32 characters'); }
        else {
            setIsPending(true);
            const reset_url = API_URL + FORGOT_PASSSWORD_PATH;
            const request_config = { headers: { 'x-api-key':API_KEY}};
            const request_body = {
                user: {
                    username: username,
                    password: password,
                },
                reset_token: token
            };
            axios.post(reset_url, request_body, request_config).then( result => {
                setMessage("Reset successful!");
                setIsPending(false);
                setIsResetedDone(true);
                setUserSession(result.data.user, result.data.token);
            }).catch( error => {
                setIsPending(false);
                if(error.response.status === 401 || error.response.status === 403) {
                    setMessage(error.response.data.message);
                }
                else {
                    setMessage("sorry ... our offices are busy.  please try again later.");
                }
            });
        }
    }

    return ( 
        <div className="reset-password">
            { !isResetDone && 
                <form onSubmit={submitHandler} autoComplete="off">
                    <h2>Reset Password</h2>
                    <div className="field-box">
                        <p>password:</p>
                        <div>
                            <input name="password" type="password" value={password} onChange={
                            (event) => { 
                                setMessage('');
                                setPassword(event.target.value);
                            }} />
                        </div>
                        <div><p className="notice">between 8 and 32 characters</p></div>
                    </div>
                    {isPending && <div className="loading">LOADING ...</div> }
                    {!isPending && <div className="button orange-background" onClick={ submitHandler }>reset password</div>}
                </form>
            }
            { message && <p className="message">{ message }</p>}
        </div>
     );
}
 
export default ResetPassword;