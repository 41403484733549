import { useState } from "react";
import { getToken, getUser, setUserSession } from "../service/AuthService";
import { API_KEY, API_URL, NOTIFICATIONS_PATH } from "../config/EndleConfig";
import axios from "axios";

const Ledger = () => {
    const user = getUser();
    const token = getToken();
    const [isPending, setIsPending] = useState(false);
    const [notifications, setNotifications] = useState(user.receive_updates);
    const [message, setMessage] = useState();

    const submitHandler = (event) => {
        event.preventDefault();
        setIsPending(true);
        setMessage();
        user.receive_updates = notifications;
        const notification_url = API_URL + NOTIFICATIONS_PATH;
        const request_config = {headers: { 'x-api-key': API_KEY }};
        const request_body = {
            user: user,
            token: token
        };
        axios.post(notification_url, request_body, request_config).then( response => {
            setIsPending(false);
            setUserSession(user, token);
            setMessage("Success, you're changes have been received by our offices and filed appropriately.");

        }).catch( error => {
            setMessage("sorry ... our offices are busy.  please try again later.");
        })
    }
    
    return ( 
        <div className="ledger">
            <form onSubmit={submitHandler} autoComplete="false">
                <h2>Notifications</h2>
                <div>
                    Receive email notifications: 
                    <input type="checkbox" name="receive notifications" checked={notifications} onChange={(event) => { setNotifications(event.target.checked); }} />
                </div>
                {!isPending && <div className="button orange-background" onClick={ submitHandler }>save</div>}
                {isPending && <div className="loading">LOADING ...</div> }
            </form>
            { message && <p className="message">{ message }</p>}
            <div className="supporting">
                <p>Why get notifications?  You ask too many questions.  But if you insist, how else will you know when new episodes, seasons or tales are made availble?  What about entries in the arcanum and archives that come available?  If you don't care to know more, fine.  Be a Talin.  But just know, no one likes Talin.</p>
            </div>
        </div>
     );
}
 
export default Ledger;