const { isValidOrdinal, InvalidDateException, getRandomInt, TEMPERATE } = require("./utils");

const HARVESTAR = "Harvestar"; const THE_WANING = "The Waning"; const CLOUDTIDE = "Cloudtide";
const WANDERING = "Wandering"; const NOEL = "Noel"; const READYTIDE = "Readytide"; const FROSTKILL = "Frostkill";
const PLANTING = "Planting"; const FLOCK = "Flock"; const GROWFEST = "Growfest"; const FIREDAWN = "Firedawn";
const FIRESKY = "Firesky"; const FIREFEST = "Firefest";

const MINDFUL = "Mindful"; const GODSDAY = "Godsday"; const TEHNDAY = "Tehnday"; const ANONDAY = "Anonday";
const BREMONDAY = "Bremonday"; const AENDAY = "Aenday"; const SELUISDAY = "Seluisday"; const BAKHSHIDAY = "Bakhshiday";
const KAELDAY = "Kaelday"; const ALTULDAY = "Altulday"; const NADULNDAY = "Nadulnday"; const KHALDAY = "Khalday";

const QUAANISH_DAY_OFFSET = 122;   // quaanish calendar start date is offset from the ordinal start date
const QUAANISH_ZERO_DAY = 2212143; // marks the day the calendar flips from negative years to positive years skiping year 0
const QUAANISH_YEAR_OFFSET = 6062; // how many negative years are in this calendar
const QUAANISH_YEAR_LENGTH = 365; 
const QUAANISH_DOW_OFFSET = 5;     // offsets the days of week by 5

const quaanish_months = [
    { name: HARVESTAR, start_day: 1, index: 1, length: 31 },
    { name: THE_WANING, start_day: 32, index: 2, length: 31 },
    { name: CLOUDTIDE, start_day: 63, index: 3, length: 30 },
    { name: WANDERING, start_day: 93, index: 4, length: 30 },
    { name: NOEL, start_day: 123, index: 5, length: 5 },
    { name: READYTIDE, start_day: 128, index: 6, length: 31 },
    { name: FROSTKILL, start_day: 159, index: 7, length: 24 },
    { name: PLANTING, start_day: 183, index: 8, length: 31 },
    { name: FLOCK, start_day: 214, index: 9, length: 30 },
    { name: GROWFEST, start_day: 244, index: 10, length: 30 },
    { name: FIREDAWN, start_day: 274, index: 11, length: 30 },
    { name: FIRESKY, start_day: 304, index: 12, length: 31 },
    { name: FIREFEST, start_day: 335, index: 13, length: 31 },
];

const quaanish_days = [
    { name: MINDFUL, abbr: "MD" },
    { name: GODSDAY, abbr: "GD" },
    { name: TEHNDAY, abbr: "TD" },
    { name: ANONDAY, abbr: "AnD" },
    { name: BREMONDAY, abbr: "BrD" },
    { name: AENDAY, abbr: "AeD" },
    { name: SELUISDAY, abbr: "SD" },
    { name: BAKHSHIDAY, abbr: "BkD" },
    { name: KAELDAY, abbr: "KaD" },
    { name: ALTULDAY, abbr: "AlD" },
    { name: NADULNDAY, abbr: "ND" },
    { name: KHALDAY, abbr: "KhD" }
];

function getMonth( month_name ) {
    const month = quaanish_months.find( ({ name }) => name.toLowerCase() === month_name.toLowerCase() );
    if( month === undefined) { throw new InvalidDateException( "invalid month" ); }
    else return month;
}

function validDate( month_name, day_of_month ) {
    const m = getMonth(month_name);
    if (day_of_month > 0 && day_of_month <= m.length) return true;
    else throw new InvalidDateException("invalid day of month");
}

const zone = TEMPERATE;

module.exports = {
    zone: zone,

    isMonth: function (month_name) {
        try {
            const m = getMonth(month_name);
            return true;
        }
        catch(e) { return false; }
    },

    getMonths: function () {
        var months = [];
        for (i = 0; i < quaanish_months.length; i++) {
            months.push({ name: quaanish_months[i].name, days: quaanish_months[i].length, start_day: quaanish_months[i].start_day });
        }
        return months;
    },

    getMonthFromYearDay: function(year_day) {
        for (i = 0; i < quaanish_months.length; i++) {
            var month = quaanish_months[i];
            var date = {};
            if (year_day < (month.start_day + month.length)) {
                date.month = month;
                date.month_day = year_day - month.start_day + 1;
                return date;
            }
        }
    },

    getOrdinalFromDateString: function (date_string) {
        const date_array = date_string.split( " " );
        // check array length 4 arrays to see if it's the Waning
        if(date_array.length === 4 && date_array[1].toLowerCase() === 'the') {
            date_array[1] = `${date_array[1]} ${date_array[2]}`;
            date_array.splice( 2, 1 );
        }
        // convert day and year to numbers
        date_array[0] = parseInt(date_array[0]);
        date_array[2] = parseInt(date_array[2]);
        return this.getOrdinal(date_array[0], date_array[1], date_array[2]); 
    },

    getOrdinal: function (day_of_month, month_name, year) {
        var ordinal_day;
        try {
            const m = getMonth(month_name);
            if (validDate(month_name, day_of_month)) {
                ordinal_day = m.start_day - 1 + day_of_month + (year * 365) + QUAANISH_ZERO_DAY;
                if (year > 0) ordinal_day = ordinal_day - 365; // we don't have a year year, so subract that form the total.
                return ordinal_day;
            }
        }
        catch(e) { throw e; }
    },

    getDayOfWeek: function (month_name, day_of_month, year) {
        const ordinal_day = module.exports.getOrdinal(day_of_month, month_name, year);
        if (ordinal_day) { return module.exports.getDOW(ordinal_day); }
        return false;
    },
    
    getDOW: function (ordinal_day) { return quaanish_days[(ordinal_day + QUAANISH_DOW_OFFSET) % quaanish_days.length].name; },

    getDate: function(ordinal_day) {
        const short_date = this.getShortDate(ordinal_day);
        const dow = this.getDOW(ordinal_day);
        return `${dow}, ${short_date}`;
    },

    getShortDate: function(ordinal_day) {
        if(!isValidOrdinal(ordinal_day)) throw new Error('invalid ordinal');
        
        const q_ordinal = ordinal_day + QUAANISH_DAY_OFFSET;
        year = Math.floor((q_ordinal - 1) / 365) + 1 - QUAANISH_YEAR_OFFSET;
        if (ordinal_day > QUAANISH_ZERO_DAY) { year = year + 1; }
        year_day = q_ordinal - Math.floor((q_ordinal + 364) / 365 - 1) * 365;
        month_date = module.exports.getMonthFromYearDay(year_day);
        return month_date.month_day + " " + month_date.month.name + " " + year;
    },

    getRandomDateInYear: (year) => {
        var year_day = getRandomInt(1, QUAANISH_YEAR_LENGTH);
        var month_date = module.exports.getMonthFromYearDay(year_day);
        var ordinal_day = module.exports.getOrdinal(month_date.month_day, month_date.month.name, year);
        return ordinal_day;
    },

    shortDate: (date_string) => {
        return date_string.substring(str.indexOf(' ') + 1);
    }
}