let quaanish = require("./quaanish");
let elven = require("./elvish");
let imperial = require("./imperial");
let three_tribe = require("./three-tribe");
let nibu = require("./nibu");
let moons = require("./moons");
const elvish = require("./elvish");
const { TEMPERATE, TROPICAL } = require('../lib/utils');

const { isNumeric, InvalidDateException } = require("./utils");
const { isValidOrdinal } = require("./utils");

const ELVEN = "elven"; const QUAANISH = "quaanish"; const NIBU = "nibu";
const IMPERIAL = "imperial";  const THREE_TRIBE = "three_tribe";

const WINTER = "winter"; const SPRING = "spring"; const SUMMER = "summer";
const AUTUMN = "autumn"; const DRY = "dry"; const RAINY = "rainy";

function getOrdinal(day_of_month, month_name, year, cycle) {
  var calendar = whichCalendar(month_name);
  var ordinal_day;
  try {
    switch (calendar) {
      case QUAANISH: ordinal_day = quaanish.getOrdinal(day_of_month, month_name, year); break;
      case ELVEN: ordinal_day = elven.getOrdinal(day_of_month, month_name, year); break;
      case NIBU: ordinal_day = nibu.getOrdinal(day_of_month, month_name, year, cycle); break;
      case IMPERIAL: ordinal_day = imperial.getOrdinal(day_of_month, month_name, year); break;
      case THREE_TRIBE: ordinal_day = three_tribe.getOrdinal(day_of_month, month_name, year); break;
    }
    return getDates( ordinal_day );
  }
  catch(e) { throw e; }
}

function getDates( ordinal ) {
  if(!isValidOrdinal(ordinal)) throw new InvalidDateException('invalid ordinal');
  const qDate = quaanish.getDate( ordinal );
  const qShortDate = quaanish.shortDate(qDate);
  const qSeason = getSeason( ordinal, quaanish.zone );
  const eDate = elven.getDate( ordinal );
  const eSeason = getSeason( ordinal, elven.zone );
  const iDate = imperial.getDate( ordinal );
  const iSeason = getSeason( ordinal, imperial.zone );
  const tDate = three_tribe.getDate( ordinal );
  const tSeason = getSeason( ordinal, three_tribe.zone );
  const nDate = nibu.getDate( ordinal );
  const nSeason = getSeason( ordinal, nibu.zone );
  const moon_phases = moons.getMoonPhases(ordinal);
  return {
    "ordinal": ordinal,
    "quaanish": qDate,
    "short_quaanish": qShortDate,
    "imperial": iDate,
    "elvish": eDate,
    "three_tribe": tDate,
    "nibu": nDate,
    "moons": moon_phases,
    "season": {
      "quaanish": qSeason,
      "imperial": iSeason,
      "elvish": eSeason,
      "three_tribe": tSeason,
      "nibu": nSeason,
    },
  };
}

function whichCalendar( month_name ) {
  if (quaanish.isMonth(month_name)) return QUAANISH;
    else if (elven.isMonth(month_name)) return ELVEN;
    else if (imperial.isMonth(month_name)) return IMPERIAL;
    else if (three_tribe.isMonth(month_name)) return THREE_TRIBE;
    else if (nibu.isMonth(month_name)) return NIBU;
    return false;
}

function getSeason( ordinal, zone ) {
  const cal_day = ordinal%365;
  if( zone === TEMPERATE ) {
    switch( true ) {
      case 0 <= cal_day && cal_day < 61: return WINTER;
      case 60 < cal_day && cal_day < 152: return SPRING;
      case 151 < cal_day && cal_day < 244: return SUMMER;
      case 243 < cal_day && cal_day < 336: return AUTUMN;
      case 335 < cal_day && cal_day < 365: return WINTER;
    }
  }
  else if( zone === TROPICAL) { 
    switch( true ) {
      case 0 <= cal_day && cal_day < 61: return RAINY;
      case 60 < cal_day && cal_day < 244: return DRY;
      case 243 < cal_day && cal_day < 365: return RAINY;
    }
  }
}

module.exports = {
  ELVEN: ELVEN, QUAANISH: QUAANISH, NIBU: NIBU, IMPERIAL: IMPERIAL
}

module.exports.getRandomDate = ( year, calendar, cycle ) => {
  year = parseInt(year);
  var ordinal = 0;
  try {
    switch(calendar) {
      case "quaanish":
        ordinal = quaanish.getRandomDateInYear(year);
        break;
      case "elvish":
        ordinal = elvish.getRandomDateInYear(year);
        break;
      case "imperial":
        ordinal = imperial.getRandomDateInYear(year);
        break;
      case "three-tribe":
        ordinal = three_tribe.getRandomDateInYear(year);
        break;
      case "nibu":
        ordinal = nibu.getRandomDate( year, cycle );
        break;
    }
    return getDates( ordinal );
  }
  catch(e) { return { "error": e.message }; }
  
}

module.exports.getOrdinal =  ( date_string ) => {
  var date_array = date_string.split( " " );
  // check array length 4 arrays to see if it's the Waning
  if(date_array.length === 4 && date_array[1].toLowerCase() === 'the') {
    date_array[1] = `${date_array[1]} ${date_array[2]}`;
    date_array.splice( 2, 1 );
  }

  // process all standard dates
    if( date_array.length === 3 ) {
      if( isNumeric(date_array[0]) && isNumeric(date_array[2]) ) {
        date_array[0] = parseInt( date_array[0] );
        date_array[2] = parseInt( date_array[2] );
        return getOrdinal(date_array[0], date_array[1], date_array[2]);
      }
      else {
        throw new InvalidDateException("invalid date");
      }
    }
    // process Nibu dates
    else if( date_array.length === 4 ) {
      if( isNumeric(date_array[0]) && isNumeric(date_array[2]) ) {
        date_array[0] = parseInt( date_array[0] );
        date_array[2] = parseInt( date_array[2] );
        return getOrdinal( date_array[0], date_array[1], date_array[2], date_array[3]);
      }
      else if( date_array[0].toLowerCase() === 'tahto') {
        date_array[2] = parseInt( date_array[2] );
        return getOrdinal( 0, `${date_array[0]} ${date_array[1]}`, date_array[2], date_array[3] );
      }
      else { throw new InvalidDateException( "invalid date "); }
    }
    else { throw new InvalidDateException( "invalid date" ); }
}

module.exports.getDates = ( ordinal ) => {
  if(!isNumeric( ordinal )) {
    return { 'error':'invalid ordinal number'};  
  }
  ordinal_day = parseInt( ordinal );
  if( !isValidOrdinal(ordinal_day) ) {
    return { 'error':'invalid ordinal number'};  
  }
  return getDates( ordinal_day );
}

module.exports.getQuaanishDate = (ordinal) => {
  if(!isNumeric( ordinal )) {
    return { 'error':'invalid ordinal number'};  
  }
  ordinal_day = parseInt( ordinal );
  if( !isValidOrdinal(ordinal_day) ) {
    return { 'error':'invalid ordinal number'};  
  }
  const qDate = quaanish.getDate( ordinal );
  const date_parts = qDate.split(" ");
  let short_date = '';
  if(date_parts.length === 4) { short_date = `${date_parts[1]} ${date_parts[2]} ${date_parts[3]}`; }
  else { short_date = `${date_parts[1]} ${date_parts[2]} ${date_parts[3]} ${date_parts[4]}`; }
  const qSeason = getSeason( ordinal, quaanish.zone );
  return {
    date: qDate,
    short_date: short_date,
    season: qSeason
  };
}