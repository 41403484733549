import { Fragment, useState } from "react";
import EditBtn from "./buttons/EditButton";
import CancelBtn from "./buttons/CancelBtn";
import SaveBtn from "./buttons/SaveButton";
import DeleteBtn from "./buttons/DeleteButton";

const TreasureItem = ({item, user, index, handler}) => {
    
    const [isEdit, setIsEdit] = useState(false);
    const [name, setName] = useState(item.name);
    const [qty, setQty] = useState(item.qty);
    const [owner, setOwner] = useState(item.owner);
    const [source, setSource] = useState(item.source);

    const edit = () => { setIsEdit(!isEdit); }
    const cancel = () => {
        console.log(name);
        edit();
        item.name = name;
        item.qty = qty;
        item.source = source;
        item.owner = owner;
    }
    const save = () => {
        edit();
        handler.submit();
    }
    const remove = () => {
        console.log("deleting");
        handler.deleteItem(index);
        handler.submit();
    }

    return ( 
        <Fragment>
            { !isEdit && (
                <Fragment>
                    <div className="num">{item.qty}</div>
                    <div>{item.name}</div>
                    <div>{item.owner}</div>
                    <div>{item.source}</div>
                    { user.role === 3 && (
                        <div className="button-flex-box">
                            <EditBtn handler={edit} />
                            <DeleteBtn handler={remove} />
                        </div>
                    )}        
                </Fragment>
            )}
            { isEdit && (
                <Fragment>
                    <input type='text' value={item.qty} 
                        onChange={ (e) => { 
                            const updated = { ...item, qty: e.target.value };
                            handler.updateItem(updated, index);
                         }}
                    />

                    <input type='text' value={item.name} 
                        onChange={ (e) => { 
                            const updated = { ...item, name: e.target.value };
                            handler.updateItem(updated, index);
                         }}
                    />
                    
                    <input type='text' value={item.owner} 
                        onChange={ (e) => { 
                            const updated = { ...item, owner: e.target.value };
                            handler.updateItem(updated, index);
                         }}
                    />
                    
                    <input type='text' value={item.source} 
                        onChange={ (e) => { 
                            const updated = { ...item, source: e.target.value };
                            handler.updateItem(updated, index);
                         }}
                    />
                    <div className="button-flex-box">
                        <SaveBtn handler={save} />
                        <CancelBtn handler={cancel} />
                    </div>
                </Fragment>
            )} 
        </Fragment>
     );
}
 
export default TreasureItem;