import { Link, NavLink } from 'react-router-dom';
import { getUser } from '../service/AuthService';
import AtlasMenu from '../components/AtlasMenu';
import AccountMenu from '../components/AccountMenu';

const Navbar = (props) => {
    const user = getUser();

    return ( 
        <nav>
            <div className="navbar">
                <Link className='logo' to="/">Endleland</Link>
                { !user && 
                    <div className="push">
                        <NavLink className="button push" to="/login">Login</NavLink>
                        <NavLink className="button" to="/register">Register</NavLink>
                    </div>
                }
                { user &&
                        <div className="push">
                            <AtlasMenu className="button"/>
                            { user.role >= 2 && (<Link className='button' to='/dnd/campaigns/gnomes'>DnD</Link>)}
                            <AccountMenu className="button"/>
                        </div>
                }
            </div>
        </nav>
     );
}
 
export default Navbar;