import { useState } from "react";
import { CITIES_CLIENT_PATH, CREATURES_CLIENT_PATH, ITEMS_CLIENT_PATH, PEOPLE_CLIENT_PATH, PLACES_CLIENT_PATH, REALMS_CLIENT_PATH } from "../config/EndleConfig";
import { useNavigate } from "react-router-dom";
import useOutsideClick from "../hooks/useOutsideClick";

const AtlasMenu = () => {

    let navigate = useNavigate();
    const [open, setOpen] = useState(false);
    
    const handleOpen = () => { setOpen(!open); }
    
    const handleMenuClick = (path) => {
        setOpen(false);
        navigate(path);
    }

    const handleOutsideClick = () => { setOpen(false); }
    const ref = useOutsideClick(handleOutsideClick);
    
    return ( 
        <div className="dropdown button">
            <div ref={ref} onClick={handleOpen}>Atlas</div>
            {open ? (<ul className="menu">
                <li><button key={REALMS_CLIENT_PATH} onClick={()=>{ handleMenuClick(REALMS_CLIENT_PATH);}}>Realms</button></li>
                <li><button key={CITIES_CLIENT_PATH} onClick={()=>{ handleMenuClick(CITIES_CLIENT_PATH);}}>Cities</button></li>
                <li><button key={PLACES_CLIENT_PATH} onClick={()=>{ handleMenuClick(PLACES_CLIENT_PATH);}}>Places</button></li>
                <li><button key={PEOPLE_CLIENT_PATH} onClick={()=>{ handleMenuClick(PEOPLE_CLIENT_PATH);}}>People</button></li>
                <li><button key={CREATURES_CLIENT_PATH} onClick={()=>{ handleMenuClick(CREATURES_CLIENT_PATH);}}>Creatures</button></li>
                <li><button key={ITEMS_CLIENT_PATH} onClick={()=>{ handleMenuClick(ITEMS_CLIENT_PATH);}}>Items</button></li>
                
            </ul>) : null }
        </div>
     );
}
 
export default AtlasMenu;