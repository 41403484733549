module.exports = {
    getUser: function() {
        const user = localStorage.getItem('user');
        if(user === 'undefined' || !user) { return false; }
        else { return JSON.parse(user); }
    },

    getToken: function() {
        return localStorage.getItem('token');
    },

    setUserSession: function(user, token) {
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('token', token);
    },

    resetUserSession: function(user, token) {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
    }
}