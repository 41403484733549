import { Fragment, useState } from "react";
import { DM_ROLE, FELLA_ROLE } from "../config/EndleConfig";
import AddBtn from "./buttons/AddButton";
import DateInput from "./DateInput";
import AttrInput from "./AttrInput";

const ManifestForm = ({manifest, setManifest, handler}) => {

    const [message, setMessage] = useState();
    console.log("manifest in form:", manifest);

    const attrHandler = {
        add: () => { 
            const updated_attrs = manifest.attributes;
            updated_attrs.push({name:'',value:''});
            setManifest({...manifest, attributes:updated_attrs});
            return;
        },
        updateName: (name, index) => {
            const updated_attrs = manifest.attributes;
            updated_attrs[index].name = name;
            setManifest({...manifest, attributes:updated_attrs});
            return;
        },
        updateValue: (value, index) => {
            const updated_attrs = manifest.attributes;
            updated_attrs[index].value = value;
            setManifest({...manifest, attributes:updated_attrs});
            return;
        },
        remove: (index) => {
            const updated_attrs = manifest.attributes;
            updated_attrs.splice(index, 1);
            setManifest({...manifest, attributes:updated_attrs});
            return;
        }
    }

    const dateHandler = {
        setOrdinal: (ordinal) => {
            setManifest({...manifest, ordinal:ordinal});
        }
    }

    const saveManifest = () => {
        console.log("manifest in save:", manifest);
        handler();
    }

    return ( 
        <div>
            {manifest && (
                <Fragment>
                    <section>
                        <div className="two-column-grid grid">
                            <div className="key">campaign:</div>
                            <div>{manifest.name}</div>

                            <div className="key">summary:</div>
                            <div>
                                <input type='text' name='summary' size='60' value={manifest.summary}
                                    onChange={(e) => {
                                        const updated = { ...manifest, summary:e.target.value };
                                        setManifest(updated);
                                    }} 
                                />
                            </div>

                            <div className="key">ACL:</div>
                            <div>
                                <select name={"acl"} 
                                    defaultValue={DM_ROLE}
                                    onChange={(e) => {
                                        const updated = { ...manifest, acl:e.target.value};
                                        setManifest(updated);
                                    }}>
                                    <option value={FELLA_ROLE}>PLAYER</option>
                                    <option value={DM_ROLE}>DM</option>
                                </select>
                            </div>
                            
                            <div className="key">Date:</div>
                            <div><DateInput handler={dateHandler} initial_ordinal={manifest.ordinal} /></div>
                        </div>
                    </section>

                    <section>
                        <div className="header-flex">
                            <h2>Attributes</h2>
                            <AddBtn handler={()=> {attrHandler.add();}} />
                        </div>
                        {manifest.attributes && (
                            <Fragment>
                                <div className="three-column-grid grid">
                                    <div className="header">name</div>
                                    <div className="header">value</div>
                                    <div className="header"></div>
                                    { manifest.attributes.map((attr, index) => (
                                        <AttrInput key={index} index={index} attr={attr} handler={attrHandler} />
                                    ))}
                                </div>
                            </Fragment>
                        )}
                    </section>

                    <section>
                        <h2>Body</h2>
                        <textarea rows='10' 
                            cols='68'
                            value={manifest.body}
                            onChange={ (e) => setManifest({...manifest, body:e.target.value})}>
                        </textarea>
                    </section>
                    <div className="button orange-background" onClick={ saveManifest }>
                        save
                    </div>
                </Fragment>
            )}    
        </div> 
    );
}
 
export default ManifestForm;