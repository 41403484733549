import { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { API_KEY, API_URL, DM_ROLE } from "../config/EndleConfig";
import axios from "axios";
import ManifestSummary from "./ManifestSummary";
import AddBtn from "./buttons/AddButton";

const ManifestList = ({user, token, name}) => {
    const [manifests, setManifests] = useState();
    const [isPending, setIsPending] = useState(false);
    const [message, setMessage] = useState();

    const request_url = API_URL + '/campaigns/' + name + '/manifests';
    const navigate = useNavigate();

    useEffect(() => {
        const request_config = {
            headers: { 'x-api-key':API_KEY },
            params: { 'username': user.username, 'token':token }
        };
        setIsPending(true);
        axios.get(request_url, request_config).then( response => {
            setIsPending(false);
            setManifests(response.data);
        }).catch( error => {
            setIsPending(false);
            if(error.response.status === 401 || error.response.status === 403) {
                if(error.response.data.message === 'invalid token') { navigate('/login'); }
                setMessage(error.response.data.message);
            } else {
                setMessage("something bad happened at the office and we're working to fix it.  please try again later.");
            }
        });
    }, [name]);

    return ( 
        <section>
            <div className="header-flex">
                <h2>What Happened</h2>
                {user.role === DM_ROLE &&  (<AddBtn handler={()=>{ navigate(`/dnd/campaigns/${name}/manifests/new`);}} />)}
                </div>
            {manifests && (<div className="two-column-grid grid">
                <div className="header">date</div>
                <div className="header">summary</div>
                { manifests && (manifests.map( (manifest, index) => (
                        <Fragment key={index}>
                            <ManifestSummary manifest={manifest} />
                        </Fragment>
                    ))
                )}
                </div>
            )}
            {isPending && <div className="loading">LOADING ...</div> }
            {message && <p className="message">{message}</p> }
        </section>
     );
}
 
export default ManifestList;