import React from "react";
import { getToken } from "../service/AuthService";
import Navbar from "../layouts/Navbar";
import WelcomeNav from "../layouts/WelcomeNav";
import Welcome from "../pages/Welcome";
import Home from "../pages/Home";
import ScrollToTop from "../components/ScrollToTop";

const HomeRoute = () => {
    const auth = getToken();
    if(!auth) {
        return(
            <>
                <WelcomeNav />
                <ScrollToTop />
                <div className="App">
                    <div className="content">
                        <Welcome />
                    </div>
                </div>
            </>
        ); 
    }
    return (
        <>
            <Navbar />
            <ScrollToTop />
            <div className="App">
                <div className="content">
                    <Home />
                </div>
            </div>
        </>
        
    );
}

export default HomeRoute;