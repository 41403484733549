import { Fragment, useState } from "react";
import CalendarBtn from "./buttons/CalendarBtn";
import { quaanish } from "endle";

const DateInput = ({handler, initial_ordinal}) => {
    const initial_date_values = {
        date_string: '',
        date_value: '',
        ordinal: ''
    };
    
    if(initial_ordinal !== undefined && initial_ordinal !== '') {
        initial_date_values.date_string = quaanish.getShortDate(initial_ordinal);
        initial_date_values.date_value = quaanish.getDate(initial_ordinal);
        initial_date_values.ordinal = initial_ordinal;
    }
    
    const [showInput, setShowInput] = useState(true);
    const [date_string, setDateString] = useState(initial_date_values.date_string);
    const [date_value, setDateValue] = useState(initial_date_values.date_value);
    const [ordinal, setOrdinal] = useState(initial_date_values.ordinal);
    const [message, setMessage] = useState();

    const calcDate = () => {
        if(date_string !== undefined && date_string !== '') {
            try {
                const ord = quaanish.getOrdinalFromDateString(date_string);
                setOrdinal(ord);
                setDateValue(quaanish.getDate(ord));
                handler.setOrdinal(ord);
                setShowInput(!showInput);
            } catch(e) {
                setMessage(e.message);
            }
        }
    }

    return (
        <Fragment>
            
            <div className="flex-row">
                    { showInput && ( <div><input name='date_string' 
                        value={date_string} 
                        onChange={(e) => { setDateString(e.target.value); }} 
                        type='text' size='20'/></div> )}
                    { !showInput && <div>{date_value}</div> }
                <div>
                    <CalendarBtn handler={ calcDate } />
                </div>
                <div className="message-text">{message}</div>
            </div>
            
        </Fragment>
     );
}
 
export default DateInput;