import { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { API_URL, VALIDATE_PATH, API_KEY } from "../config/EndleConfig";
import axios from "axios";
import { setUserSession } from "../service/AuthService";

const Validate = () => {
    const {username, token} = useParams();
    const [isPending, setIsPending] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [message, setMessage] = useState();
    const [isInvalid, setIsInvalid] = useState(false);

    function sendValidation() {
        setIsPending(true);
        setMessage();
        setIsInvalid(false);
        const url = API_URL + VALIDATE_PATH;
        const config = {
            headers: { 'x-api-key':API_KEY },
            params: { 'u': username }
        }
        axios.get(url, config).then( response => {
            setIsPending(false);
            setMessage("Please check your email for a new validation link.");
        }).catch( error => {
            setIsPending(false);
            setMessage("Something went wrong, please try again later.");
        });
    }

    useEffect(() => {
        if(username.trim() === '' || token.trim() === '') {
            setMessage('invalid credentials');
        }
        else {
            const request_url = API_URL + VALIDATE_PATH;
            const request_config = {headers: { 'x-api-key':API_KEY }};
            const request_body = {
                u: username,
                t: token
            };
            setIsPending(true);
            axios.post(request_url, request_body, request_config).then( response => {
                setIsPending(false);
                setUserSession(response.data.user, response.data.token);
                setIsSuccess(true);
            }).catch( error => {
                setIsPending(false);
                if(error.response.status === 401 || error.response.status === 403) {
                    if(error.response.data.message === 'invalid token') {
                        setIsInvalid(true);
                    }
                    setMessage(error.response.data.message);
                } else {
                    setMessage("something bad happened at the office and we're working to fix it.  please try again later.");
                }
            });
        }
    }, []);

    return ( <div className="validation">
        <h2>Email Validation</h2>
        { isPending && <div className="loading">PROCESSING ...</div> }
        { message && <p className="message">{message}</p> }
        { isInvalid && <div className="resend">
            <p>Would you like a new validation email?  Click below.</p>
            <div className="button" onClick={ sendValidation }>resend</div>
        </div>}
        { isSuccess && <Navigate to="/" /> }
    </div> );
}
 
export default Validate;