const NEW_MOON        = "new-moon";       const WANING_CRESCENT = "waning-crescent";  const FIRST_QUARTER   = "first-quarter";
const WAXING_GIBBOUS  = "waxing-gibbous"; const FULL_MOON = "full-moon";              const WANING_GIBBOUS  = "waning-gibbous"; 
const THIRD_QUARTER   = "third-quarter";  const WAXING_CRESCENT = "waxing-crescent";

const phase_names = [NEW_MOON, WANING_CRESCENT, FIRST_QUARTER, WAXING_GIBBOUS, FULL_MOON, WANING_GIBBOUS,
    THIRD_QUARTER, WAXING_CRESCENT
  ];

module.exports = {
    getThenalPhase: (ordinal) => {
        var cycle_day = (ordinal+62)%29;
          var phase_number;
          if(0<= cycle_day && cycle_day <3) phase_number = 0;
          else if(3<= cycle_day && cycle_day <6) phase_number = 1;
          else if(6<= cycle_day && cycle_day <10) phase_number = 2;
          else if(10<= cycle_day && cycle_day <14) phase_number = 3;
          else if(14<= cycle_day && cycle_day <18) phase_number = 4;
          else if(18<= cycle_day && cycle_day <22) phase_number = 5;
          else if(22<= cycle_day && cycle_day <26) phase_number = 6;
          else if(26<= cycle_day) phase_number = 7;
          return phase_names[phase_number];
      },
      
      getSanavaalPhase: (ordinal) => {
        var cycle_day = (ordinal+62)%24;
        var phase_number;
        if(0<= cycle_day && cycle_day <3) phase_number = 0;
        else if(3<= cycle_day && cycle_day <6) phase_number = 1;
        else if(6<= cycle_day && cycle_day <9) phase_number = 2;
        else if(9<= cycle_day && cycle_day <12) phase_number = 3;
        else if(12<= cycle_day && cycle_day <15) phase_number = 4;
        else if(15<= cycle_day && cycle_day <18) phase_number = 5;
        else if(18<= cycle_day && cycle_day <21) phase_number = 6;
        else if(21<= cycle_day) phase_number = 7;
        return phase_names[phase_number];
      },
      
      getTuelalPhase: (ordinal_day) => {
        var phase_number = (ordinal_day + 62)%8;
        return phase_names[phase_number];
      },
      
      getMoonPhases: (ordinal) => {
        var thenal = module.exports.getThenalPhase(ordinal);
        var sanavaal = module.exports.getSanavaalPhase(ordinal);
        var tuelal = module.exports.getTuelalPhase(ordinal);
        return { "thenal": thenal, "sanavaal": sanavaal, "tuelal":tuelal };
      }
}