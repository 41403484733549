import { Link } from "react-router-dom";

const Atlas = () => {
    return ( 
        <div className="atlas">
            <h1>Atlas</h1>
            <div><Link to="/atlas/realms">Realms</Link></div>
            <div><Link to="/atlas/cities">Cities</Link></div>
            <div><Link to="/atlas/people">People</Link></div>
        </div>
     );
}
 
export default Atlas;