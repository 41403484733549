import { useParams, useNavigate, Link } from "react-router-dom";
import { API_URL, API_KEY } from "../config/EndleConfig";
import { getUser, getToken } from "../service/AuthService";
import { useState, useEffect, Fragment } from "react";
import axios from "axios";

const Entries = () => {

    const {resource} = useParams();
    const user = getUser();
    const token = getToken();
    const [isPending, setIsPending] = useState(false);
    const [entries, setEntries] = useState([]);
    const [message, setMessage] = useState();
    let navigate = useNavigate();

    useEffect(() => {
        setMessage(null);
        const request_url = API_URL + '/' + resource;
        const request_config = {
            headers: { 'x-api-key':API_KEY },
            params: { 'username': user.username, 'token':token }
        };
        setIsPending(true);
        axios.get(request_url, request_config).then( response => {
            setIsPending(false);
            setEntries(response.data);
        }).catch( error => {
            setIsPending(false);
            setEntries(null);
            if(error.response.status === 401 || error.response.status === 403) {
                if(error.response.data.message === 'invalid token') {
                    navigate('/login');
                }
                setMessage(error.response.data.message);
            } else if(error.response.status === 400) {
                setMessage("Nothing found");
            }else {
                setMessage("something bad happened at the office and we're working to fix it.  please try again later.");
            }
        });
    }, [resource]);
    

    return ( 
        <div className="entry-list">
            {isPending && <div className="loading">LOADING ...</div> }
            {!isPending &&
                <div>
                    <h1>{resource}</h1>
                    {message && <p className="message">{message}</p> }
                    
                    <div className="two-column-grid grid padded-grid">
                    { entries && entries.length > 0 && 
                            entries.map((entry, index) => (
                            <Fragment key={index}>
                                <div className="big-key">
                                    <Link to={`/atlas/${entry.resource}/${entry.url_name}`}>{entry.name}</Link>
                                </div>
                                <div>{entry.summary}</div>
                            </Fragment>
                        ))
                    }
                    </div>
                </div>
            }
        </div>
     );
}
// entries.map(( component ) => <EntrySummary list_item={ component } key={component.url_name}/> )
export default Entries;