import { Fragment, useState } from "react";
import { calendars } from "endle";
import { Link } from "react-router-dom";

const ManifestSummary = ({manifest}) => {
    const quaanish = calendars.getQuaanishDate(manifest.ordinal);
    const url = `/dnd/campaigns/${manifest.name}/manifests/${manifest.ordinal}`;

    return ( 
        <Fragment>
            <div className="key">
                <Link to={url}>
                    {quaanish.short_date}
                </Link>
            </div>
            <div>{manifest.summary}</div>
        </Fragment>
     );
}
 
export default ManifestSummary;