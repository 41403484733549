import { Fragment, useState } from "react";
import SaveBtn from "./buttons/SaveButton";
import CancelBtn from "./buttons/CancelBtn";

const TreasureItemInput = ({handler}) => {
    const empty_item = { qty:'', name:'', owner:'', source:''};
    const [item, setItem] = useState({...empty_item});

    const save = () => {
        const new_item = {...item};
        handler.addItem(new_item);
        setItem({...empty_item});
        handler.hideAdd();
    }

    const cancel = () => {
        setItem({...empty_item});
        handler.hideAdd();
    }

    return ( 
        <div className="dm-treasure-grid">
            <input type='text' value={item.qty} 
                onChange={ (e) => { 
                    const updated = { ...item, qty: e.target.value };
                    setItem(updated);
                    }}
            />

            <input type='text' value={item.name} 
                onChange={ (e) => { 
                    const updated = { ...item, name: e.target.value };
                    setItem(updated);
                    }}
            />
            
            <input type='text' value={item.owner} 
                onChange={ (e) => { 
                    const updated = { ...item, owner: e.target.value };
                    setItem(updated);
                    }}
            />
            
            <input type='text' value={item.source} 
                onChange={ (e) => { 
                    const updated = { ...item, source: e.target.value };
                    setItem(updated);
                    }}
            />
            <div className="button-flex-box">
                <SaveBtn handler={save} />
                <CancelBtn handler={cancel} />
            </div>
        </div>
     );
}
 
export default TreasureItemInput;