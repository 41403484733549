import { NavLink } from 'react-router-dom';

const WelcomeNav = () => {
    return ( 
        <nav>
            <div className="navbar">
                <div className="push">    
                    <NavLink className="button" to="/login">Login</NavLink>
                    <NavLink className="button orange-background" to="/register">Register</NavLink>
                </div>
            </div>
        </nav>
     );
}
 
export default WelcomeNav;