import { Fragment, useState } from "react";
import TreasureItem from "./TreasureItem";
import AddBtn from "./buttons/AddButton";
import TreasureItemInput from "./TreasureItemInput";
import { DM_ROLE } from "../config/EndleConfig";

const TreasureList = ({treasure, user, handler}) => {

    const treasure_components = [];
    const [showAdd, setShowAdd] = useState(false);

    const updater = {
        updateItem: (item, index) => {
            treasure[index] = item;
            handler.updateTreasure(treasure);
        },

        addItem: (item) => {
            treasure.push(item);
            handler.submit();
        },

        deleteItem: (index) => {
            treasure.splice(index, 1);
            handler.updateTreasure(treasure);
        },

        submit: () => {
            handler.submit();
        },

        hideAdd: () => {
            setShowAdd(!showAdd);
        }
    };
    
    treasure.forEach( (item, index) => {
        treasure_components.push( <TreasureItem key={index} index={index} user={user} item={item} handler={updater} />);
    })

    return ( 
        <section>
            <div className="header-flex">
                <h2>treasure</h2>
                { !showAdd && user.role === DM_ROLE && <AddBtn handler={()=> {setShowAdd(!showAdd);}} /> }
            </div>
            <div className={user.role === DM_ROLE? 'five-column-grid grid' : 'four-column-grid grid'}>
                <div className="header">qty</div>
                <div className="header">item</div>
                <div className="header">owner</div>
                <div className="header">source</div>
                { user.role === 3 && ( <div className="header">dm</div>) }
                { treasure_components.map(( component ) => component )}
            </div>
            { showAdd && user.role ===3 &&  (
                <div>
                    <TreasureItemInput handler={updater} />
                </div>
            )}
        </section>
     );
}
 
export default TreasureList;