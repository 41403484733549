import React from "react";
import { Outlet } from "react-router-dom";
import PublicNavbar from "../layouts/PublicNavbar";

const PublicRoute = () => {
    return (
        <>
            <PublicNavbar />
            <div className="App">
                <div className="content">
                    <Outlet />
                </div>
            </div>
        </>
    );
}

export default PublicRoute;