import { Fragment, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { DM_ROLE, API_URL, API_KEY } from "../../config/EndleConfig";
import ManifestForm from "../../components/ManifestForm";
import { getToken, getUser } from "../../service/AuthService";
import axios from "axios";

const AddManifest = () => {
    const {name} = useParams();
    const [isPending, setIsPending] = useState(false);
    const [message, setMessage] = useState();

    const user = getUser();
    const token = getToken();
    const navigate = useNavigate();
    
    const [manifest, setManifest] = useState({
        "summary": "",
        "acl": DM_ROLE,
        "attributes": [
            {"name":"session", "value":''},
            {"name":"play date", "value":''},
        ],
        "name": name,
        "ordinal": '',
        "body":""
    });

    const saveManifest = () => {
        setIsPending(true);
        const config = {headers:{'x-api-key':API_KEY}};
        const success_path = `/dnd/campaigns/${manifest.name}/manifests/${manifest.ordinal}`;
        const url = API_URL + '/campaigns/' + manifest.name + '/manifests'  ;
        
        const body = {
            manifest:manifest,
            user: { username:user.username, token:token }
        } 
        axios.post(url, body, config).then( response => {
            navigate(success_path);
        }).catch(error => {
            if(error.response.status === 401 || error.response.status === 403) {
                setMessage(error.response.data.message);
                setIsPending(false);
            } else {
                setMessage(("sorry ... our offices are busy.  please try again later."));
            }
        });
    }

    return (
        <Fragment>
            <h1>Add Manifest</h1>
            {manifest && <ManifestForm manifest={manifest} setManifest={setManifest} handler={saveManifest} />}
            {message && <p className="message">{message}</p> }
            {isPending && <div className="message-text">LOADING ...</div> }
        </Fragment>
    );
}
 
export default AddManifest;