import { useNavigate } from "react-router-dom";
import { GLYPH_CLIENT_PATH } from "../config/EndleConfig";

const ImageBox = ({ as, src, ...otherProps }) => {

    let navigate = useNavigate();
    const viewLarge = () => { navigate(GLYPH_CLIENT_PATH, {state:{as:as, src:src,otherProps:{...otherProps}}}); }
    return ( <img as={as} src={src} {...otherProps} onClick={ viewLarge }/> );
}
 
export default ImageBox;