const { InvalidDateException, getRandomInt, TEMPERATE } = require("../lib/utils");

const JEROTH = "Jeroth"; const MULELAL = "Mulelal"; const BASELAL = "Baselal"; const HONOLAL = "Honolal";
const KHAVIL = "Khavil"; const NABIKALIL = "Nabikalil"; const MISANINA = "Misanina"; const SABIS = "Sabis";
const BOLOAL = "Boloal"; const HABOAL = "Haboal"; const WOLALEL = "Wolalel"; const WYTH = "Wyth";

const SILVINOO = "Silvinoo"; const NUSILTHAL = "Nusithal"; const ILOTHOL = "Ilothol"; const WILTH = "Wilth";
const TALNOTH = "Talnoth"; const THITH = "Thith";

const ELVISH_YEAR_LENGTH = 348;

const elven_months = [
  {name:  JEROTH, start_day: 1,   index: 1,  length: 29 },
  {name:  MULELAL, start_day: 30,  index: 2,  length: 29 },
  {name:  BASELAL, start_day: 59,  index: 3,  length: 29 },
  {name:  HONOLAL, start_day: 88,  index: 4,  length: 29 },
  {name:  KHAVIL, start_day: 117, index: 5,  length: 29 },
  {name:  NABIKALIL, start_day: 146, index: 6,  length: 29 },
  {name:  MISANINA, start_day: 175, index: 7,  length: 29 },
  {name:  SABIS, start_day: 204, index: 8,  length: 29 },
  {name:  BOLOAL, start_day: 233, index: 9,  length: 29 },
  {name:  HABOAL, start_day: 262, index: 10, length: 29 },
  {name:  WOLALEL, start_day: 291, index: 11, length: 29 }, 
  {name:  WYTH, start_day: 320, index: 12, length: 29 },
];

const elven_days = [
  {name: SILVINOO,  abbr: "SO" },
  {name: NUSILTHAL, abbr: "NL" },
  {name: ILOTHOL,   abbr: "IL" },
  {name: WILTH,     abbr: "WT" },
  {name: TALNOTH,   abbr: "TH" },
  {name: THITH,     abbr: "TiH" }
];

function getMonth( month_name ) {
  const month = elven_months.find( ({ name }) => name == month_name );
  if( month === undefined) throw new InvalidDateException( "invalid month" );
  else return month;
}

function validDate( month_name, day_of_month ) {
  const m = getMonth(month_name);
  if (day_of_month > 0 && day_of_month <= m.length) return true;
  else throw new InvalidDateException("invalid day of month");
}

const zone = TEMPERATE;

module.exports = {
    zone: zone,
    isMonth: function (month_name) {
      try {
        const m = getMonth(month_name);
        return true;
      }
      catch(e) {
        return false;
      }
    },
    
    getMonthFromYearDay: function(year_day) {
        var month_date = {
            month: elven_months[Math.floor((year_day-1)/29)],
        };
        month_date.month_day = year_day - month_date.month.start_day + 1;
        return month_date;
    },
   
    getOrdinal:
      function(day_of_month, month_name, year) {
        try {
          if(validDate(month_name, day_of_month)) {
            const m = getMonth(month_name);
            return (year-1)*348 + m.start_day - 1 + day_of_month;
          }
        }
        catch(e) { throw e; }
    },

    getDate: (ordinal_day) => {
      var year, year_day, month, month_day, dow;
  
      year_day = ordinal_day - Math.floor((ordinal_day+347)/348-1) *348;
      year = Math.floor((ordinal_day+347)/348);
      month = elven_months[Math.floor((year_day-1)/29)];
      month_day = year_day - month.start_day + 1;
      dow = elven_days[(ordinal_day-1)%6].name;
      return dow + ", " + month_day + " " + month.name + " " + year;
    },
    getMonths: () => {
        var months = [];
        for (i = 0; i < elven_months.length; i++) {
            months.push({ name: elven_months[i].name, days: elven_months[i].length, start_day: elven_months[i].start_day });
        }
        return months;
    },
    getRandomDateInYear: (year) => {
        var year_day = getRandomInt(1, ELVISH_YEAR_LENGTH);
        var month_date = module.exports.getMonthFromYearDay(year_day);
        var ordinal_day = module.exports.getOrdinal(month_date.month_day, month_date.month.name, year);
        return ordinal_day;
        // return module.exports.getDate(ordinal_day);
    }
  }