const Home = () => {
    return ( 
        <div className="home">
            <h1>The Tapestry</h1>
            <p>The first tale to be released.  This is the story of how the lives of three, maybe four people ... ok, possibly five depending on how you count gnomes ... intersect as everyone, and it really will feel like everyone, is looking for an axe that the one person who has no interest in it possesses.</p>
            <p>Release date for episode one is prior to the Noel Festivities.  Which roughly translates to sometime in your mid-December.</p>
        </div>
     );
}
 
export default Home;