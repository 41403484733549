import { useState } from "react";
import axios from "axios";
import { API_KEY, API_URL, REGISTER_PATH } from "../config/EndleConfig";

const Register = () => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [receive_updates, setReceiveUpdates] = useState(true);
    const [message, setMessage] = useState();
    const [isPending, setIsPending] = useState(false);
    const [isRegistered, setIsRegistered] = useState(false);

    const submitHandler = (event) => {
        event.preventDefault();
        if(username.trim() === '' || password.trim() === '' || email.trim() === '') {
            setMessage('All fields are required');
        }
        else if(username.length <3 || username.length > 24) { setMessage('valid usernames are between 3 and 24 characters'); }
        else if(password.length <8 || password.length > 32) { setMessage('valid passwords are between 8 and 32 characters'); }
        else {
            setIsPending(true);
            const register_url = API_URL + REGISTER_PATH;
            const request_config = {headers: { 'x-api-key':API_KEY }};
            const request_body = {
                username: username,
                password: password,
                email: email,
                receive_updates: receive_updates
            };
            axios.post(register_url, request_body, request_config).then( response => {
                setMessage("Registration successful.  Please check your email for a confirmation link, and click the link to continue.");
                setIsPending(false);
                setIsRegistered(true);
            }).catch(error => {
                setIsPending(false);
                if(error.response.status === 401 || error.response.status === 403) {
                    setMessage(error.response.data.message);
                } else {
                    setMessage(("sorry ... our offices are busy.  please try again later."));
                }
            });
        }
        
    }


    return ( 
        <div className="register">
            { !isRegistered && 
                <form onSubmit={submitHandler} autComplete="on">
                    <h2>Register</h2>
                    <section>
                        <div className="two-column-grid grid">
                            <div>username:</div>
                            <div>
                                <input name="username" autoComplete="off" type="text" value={username} onChange={
                                    (event) => {
                                        setMessage('');
                                        setUsername(event.target.value);
                                }}/>
                            </div>
                        
                            <div>email:</div>
                            <div>
                                <input name="email" type="email" autoComplete="email" value={email} onChange={
                                    (event) => {
                                        setMessage('');
                                        setEmail(event.target.value);
                                }}/>
                            </div>
                        
                            <div>password:</div>
                            <div>
                                <input name="password" type="password" value={password} placeholder='8 - 32 characters' onChange={
                                    (event) => {
                                        setMessage('');
                                        setPassword(event.target.value);
                                }} />
                            </div>
                        
                        <div>receive updates by email:</div>
                        <div> 
                            <input type="checkbox" name="receive notifications" checked={receive_updates} onChange={(event) => { setReceiveUpdates(event.target.checked); }} /></div>
                        </div>
                        {isPending && <div className="loading">LOADING ...</div> }
                        {!isPending && <div className="button orange-background" onClick={ submitHandler }>register</div>}
                    </section>
                </form>}
            { message && <p className="message">{ message }</p>}
            <div className="supporting">
                <p>Why register?  Why do anything?  Well, surely there are some gods out there that will see this as an offering.  But in exchange for what?  Well, that's between you and your gods.  As for why others have registered?  That's easy.  Registered readers gain access to the various arcanum and archives otherwise withheld from the rabble.  It's a distinction of sorts.  Registration.  Distinguish yourself.</p>
            </div>
        </div>
     );
}
 
export default Register;