import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getUser, getToken } from "../service/AuthService";
import { API_URL, API_KEY } from "../config/EndleConfig";
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import { customLink, enlargingImg } from "../service/MarkdownService";
import Attributes from "../components/Attributes";

const Entry = () => {
    const {resource, name} = useParams();
    const user = getUser();
    const token = getToken();
    const [isPending, setIsPending] = useState(false);
    const [entry, setEntry] = useState([]);
    const [message, setMessage] = useState();
    let navigate = useNavigate();

    useEffect(() => {
        const request_url = API_URL + '/' + resource + '/' + name;
        const request_config = {
            headers: { 'x-api-key':API_KEY },
            params: { 'username': user.username, 'token':token }
        };
        setIsPending(true);
        axios.get(request_url, request_config).then( response => {
            setIsPending(false);
            setEntry(response.data);
        }).catch( error => {
            setIsPending(false);
            if(error.response.status === 401 || error.response.status === 403) {
                if(error.response.data.message === 'invalid token') {
                    navigate('/login');
                }
                setMessage(error.response.data.message);
            } else {
                setMessage("something bad happened at the office and we're working to fix it.  please try again later.");
            }
        });
    }, [name]);

    return ( 
        <div className="entry">
            {isPending && <div className="loading">LOADING ...</div> }
            {message && <p className="message">{message}</p> }
            { entry && <div>
                <h1 className="with-summary">{entry.name}</h1>
                <p>{entry.summary}</p>
                <Attributes attributes={entry.attributes} />
                <ReactMarkdown 
                    components={{a: customLink, img:enlargingImg }} 
                    className="entry-body">{ entry.body }
                </ReactMarkdown>
            </div> }
        </div>
     );
}
 
export default Entry;