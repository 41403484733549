import { Link, NavLink, useLocation } from 'react-router-dom';

const PublicNavbar = () => {

    const location = useLocation();
    const is_register = location.pathname === '/register' ? true : false;
    const is_login = location.pathname === '/login' ? true : false;

    return ( 
        <nav>
            <div className="navbar">
                <Link className='logo' to="/">Endleland</Link> 
                <div className="push">
                    { is_register && <NavLink className="button push" to="/login">Login</NavLink> }
                    { is_login && <NavLink className="button" to="/register">Register</NavLink> }
                </div>
            </div>
        </nav>
     );
}
 
export default PublicNavbar;