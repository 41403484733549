import { Fragment } from "react";
import CancelBtn from "./buttons/CancelBtn";

const AttrInput = ({attr, index, handler}) => {
    return ( 
        <Fragment>
            <div>
                <input type='text' 
                    name='name'
                    size='18'
                    value={attr.name} 
                    onChange={(e) => {
                        handler.updateName(e.target.value, index);
                    }}
                />
            </div>
            <div>
                <input type='text' 
                    name='value'
                    size='36'
                    value={attr.value} 
                    onChange={(e) => {
                        handler.updateValue(e.target.value, index);
                    }}
                />
            </div>
            <div className="icon-flex">
                <CancelBtn handler={()=>{handler.remove(index);}} />
            </div>
        </Fragment>
     );
}
 
export default AttrInput;