const { isValidOrdinal, InvalidDateException, getRandomInt, TEMPERATE } = require( './utils' );

// month name constants
const NAEMBAE = "Naembae"; const AELBAE   = "Aelbae";   const KYSTBAE = "Kystbae";
const MAELBAE = "Maelbae"; const ROSAEBAE = "Rosbae";   const BYBAE   = "Bybae";
const EISBAE  = "Eisbae";  const ISPALBAE = "Ispalbae"; const PALYBAE = "Palybae";
const SUNOBAE = "Sunobae"; const BALBAE   = "Balbae";   const AENBAE  = "Aenbae";
const NOEBAE  = "Noebae";

// day of week name constants
const MRIDON = "Mridon";   const MEDDON = "Meddon";   const BREMDON = "Bremdon";
const LELDON = "Leldon";   const RAEDON = "Raedon";   const BREZDON = "Brezdon";
const ILDON = "Ildon";     const MALDON = "Maldon";   const TILSDON = "Tilsdon";
const ULSDON = "Ulsdon";

const IMPERIAL_ZERO_DAY = 1907276; 
const IMPERIAL_YEAR_OFFSET = 5227;
const IMPERIAL_YEAR_LENGTH = 365;
const IMPERIAL_DAY_OFFSET = 214;

const imperial_months = [
  {name:  NAEMBAE,  start_day: 1,     index: 1,   length: 30 },
  {name:  AELBAE,   start_day: 31,    index: 2,   length: 30 },
  {name:  KYSTBAE,  start_day: 61,    index: 3,   length: 30 },
  {name:  MAELBAE,  start_day: 91,    index: 4,   length: 30 },
  {name:  ROSAEBAE, start_day: 121,   index: 5,   length: 30 },
  {name:  BYBAE,    start_day: 151,   index: 6,   length: 30 },
  {name:  NOEBAE,   start_day: 181,   index: 7,   length: 5  },
  {name:  EISBAE,   start_day: 186,   index: 8,   length: 30 },
  {name:  ISPALBAE, start_day: 216,   index: 9,   length: 30 },
  {name:  PALYBAE,  start_day: 246,   index: 10,  length: 30 },
  {name:  SUNOBAE,  start_day: 276,   index: 11,  length: 30 },
  {name:  BALBAE,   start_day: 306,   index: 12,  length: 30 },
  {name:  AENBAE,   start_day: 336,   index: 13,  length: 30 }
];

const imperial_days = [
  {name: MRIDON,    abbr: "MI" },
  {name: MEDDON,    abbr: "MD" },
  {name: BREMDON,   abbr: "BM" },
  {name: LELDON,    abbr: "LL" },
  {name: RAEDON,    abbr: "RE" },
  {name: BREZDON,   abbr: "BZ" },
  {name: ILDON,     abbr: "IL" },
  {name: MALDON,    abbr: "ML" },
  {name: TILSDON,   abbr: "TS" },
  {name: ULSDON,    abbr: "US" }
];

function getMonth( month_name ) {
    const month = imperial_months.find( ({ name }) => name == month_name );
    if( month === undefined) throw new InvalidDateException( "invalid month" );
    else return month;
}

function validDate( month_name, day_of_month) {
    const m = getMonth(month_name);
    if(day_of_month > 0 && day_of_month <= m.length) { return true; }
    else throw new InvalidDateException("invalid day of month");
}

const zone = TEMPERATE;

module.exports = {
    zone: zone,
    
    isMonth: function (month_name) {
        try {
            const m = getMonth(month_name);
            return true;
        }
        catch(e) { return false; } 
    },

    getMonths: () => {
        var months = [];
        for (i = 0; i < imperial_months.length; i++) {
            months.push({ name: imperial_months[i].name, days: imperial_months[i].length, start_day: imperial_months[i].start_day });
        }
        return months;
    },

    getMonthFromYearDay: function(year_day) {
        for (i = 0; i < imperial_months.length; i++) {
            var month = imperial_months[i];
            var date = {};
            if (year_day < (month.start_day + month.length)) {
                date.month = month;
                date.month_day = year_day - month.start_day + 1;
                return date;
            }
        }
    },

    getOrdinal:
      function(day_of_month, month_name, year) {
        var ordinal_day;
        try {
            const m = getMonth(month_name);
            if (validDate(month_name, day_of_month)) {
                ordinal_day = m.start_day - 1 + day_of_month + (year * 365) + IMPERIAL_ZERO_DAY;
                if (year > 0) ordinal_day = ordinal_day - 365; // we don't have a year year, so subract that form the total.
                return ordinal_day;
            }
        }
        catch(e) { throw e; }
    },

    getDOW: function (ordinal_day) { return imperial_days[(ordinal_day) % imperial_days.length].name; },
    getDate: (ordinal_day) => {
        var imperial_ordinal, year, year_day, month_date, dow;

        function getYear() {
            // there is no '0' year in the Quaanish calendar so we add one to the year to skip zero
            year = Math.floor((imperial_ordinal - 1) / 365) + 1 - IMPERIAL_YEAR_OFFSET;
            if (ordinal_day > IMPERIAL_ZERO_DAY) { year = year + 1; }

            year_day = imperial_ordinal - Math.floor((imperial_ordinal + 364) / 365 - 1) * 365;
        }

        if (isValidOrdinal(ordinal_day)) {
            imperial_ordinal = ordinal_day + IMPERIAL_DAY_OFFSET;
            getYear();
            month_date = module.exports.getMonthFromYearDay(year_day);
            dow = module.exports.getDOW(ordinal_day);
            return dow + ", " + month_date.month_day + " " + month_date.month.name + " " + year;
        }
        return false;
    },
    getRandomDateInYear: (year) => {
        var year_day = getRandomInt(1, IMPERIAL_YEAR_LENGTH);
        var month_date = module.exports.getMonthFromYearDay(year_day);
        var ordinal_day = module.exports.getOrdinal(month_date.month_day, month_date.month.name, year);
        return ordinal_day;
        // return module.exports.getDate(ordinal_day);
    }
  }