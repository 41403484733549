const { isNumeric, InvalidDateException, getRandomInt, TROPICAL } = require("./utils");

const NIBU_ORDINAL_START = 1478858; // this is day zero, or the last day of negative cycles
const NIBU_CYCLE = 300;
const NIBU_YEAR_LENGTH = 365;
const UMANI = "Umani";  const TAHTO_ANAY = "Tahto Anay"; const JOMO = "Jomo";

const nibu_months = [
    { name: UMANI, start_day: 1, index: 1, length: 182 },
    { name: TAHTO_ANAY, start_day: 183, index: 2, length: 1 },
    { name: JOMO, start_day: 184, index: 3, length: 182 }
];

function getMonth( month_name ) {
    const month = nibu_months.find( ({ name }) => name == month_name );
    if( month === undefined) throw new InvalidDateException( "invalid month" );
    else return month;
}

function parse_cycle_string( cycle_string ) {
    let cycle = cycle_string;
    if( typeof cycle === 'string' || cycle instanceof String ) {
        if( cycle.startsWith('c') ) { cycle = cycle.substring(1); }    
    }
    if( isNumeric( cycle )) {
        return parseInt( cycle );
    }
    return false;
}

function isValidYear( year ) {
    if( isNumeric( year ) && year <= NIBU_CYCLE && year > 0 ) return true;
    throw new InvalidDateException( "invalid year" );
}

function getMonthFromYearDay( year_day ) {
    for( i=0; i<nibu_months.length; i++ ) {
        var month = nibu_months[i];
        var date ={};
        if( year_day < (month.start_day + month.length)) {
            date.month = month;
            date.month_day = year_day - month.start_day + 1;
            return date;
        }
    }
}

const zone = TROPICAL;

module.exports = {
    zone: zone,

    getOrdinal: function (day_of_month, month_name, year, cycle_str) {
        let cycle = parse_cycle_string( cycle_str );
        if( cycle !== false ) {
            let nibu_year = (cycle * 300) + year - 1;
            let day_of_year = day_of_month;
            switch (month_name) {
                case "Umani": break;
                case "Tahto Anay": day_of_year += 183; break;
                case "Jomo": day_of_year += 183; break;
            }
            let nibu_day = (nibu_year * 365) + day_of_year;
            return NIBU_ORDINAL_START + nibu_day;
        }
        throw new InvalidDateException( "invalid cycle");
    },

    getDate: function (ordinal) {
        let nibu_days = ordinal - NIBU_ORDINAL_START;
        let nibu_years = Math.floor((nibu_days - 1) / 365);
        let cycle = Math.floor(nibu_years / NIBU_CYCLE);
        let cycle_year = (nibu_years % NIBU_CYCLE) + 1;
        let year_day = nibu_days - 365 * (nibu_years);
        let month, month_day = "";

        if (year_day == 183) {
            month = "Tahto Anay";
            month_day = "";
        }
        else if (year_day > 183) {
            month = "Jomo";
            month_day = year_day - 183;
        }
        else {
            month = "Umani";
            month_day = year_day;
        }

        if(month_day !== "") return month_day + " " + month + " " + cycle_year + " c" + cycle;
        else return month + " " + cycle_year + " c" + cycle;
        
    }
}

module.exports.isMonth = ( month_name ) => {
    try {
        const m = getMonth(month_name);
        return true;
    }
    catch(e) { return false; }
}

module.exports.getRandomDate = ( year, cycle ) => {
    if( parse_cycle_string(cycle) && isValidYear( year )) {
        const year_day = getRandomInt( 1, NIBU_YEAR_LENGTH );
        const month_date = getMonthFromYearDay( year_day );
        const ordinal_day = module.exports.getOrdinal( month_date.month_day, month_date.month_name, year, cycle );
        return ordinal_day;
    }
    else { throw new InvalidDateException("invalid cycle"); }
}