
const Adventure = () => {
    return ( 
        <div>
            <h1>The Gnome Adventure: Journey to the White Wood</h1>
            
        </div>
     );
}
 
export default Adventure;