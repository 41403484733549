import { useLocation } from "react-router-dom";

const ViewImage = () => {
    const location = useLocation();
    
    return ( 
        <div className="view-image">
            { location.state && (
                <img src={location.state.src} {...location.state.otherProps} />
            )}
            { !location.state && (
                <p className="message">what you seek is not available to you.</p>
            )}
        </div>
     );
}
 
export default ViewImage;