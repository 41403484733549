import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getToken, getUser } from "../service/AuthService";
import { DM_ROLE } from "../config/EndleConfig";
import Navbar from "../layouts/Navbar";
import ScrollToTop from "../components/ScrollToTop";

const DMRoute = ({ redirect_path = '/' }) => {
    const auth = getToken();
    const user = getUser();

    if(!auth) return <Navigate to={ redirect_path } replace />;
    if(user.role < DM_ROLE) return <Navigate to={ '/ledger'} replace />;
    return (
        <>
            <Navbar />
            <ScrollToTop />
            <div className="App">
                
                <div className="content">
                    <Outlet />
                </div>
            </div>
        </>
        
    );
}

export default DMRoute;