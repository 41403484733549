module.exports = {
    RED:    "\x1b[31m",
    BLUE:   "\x1b[34m",
    GREEN:  "\x1b[32m",
    YELLOW: "\x1b[33m",
    RESET:  "\x1b[0m",

    space: (spaces) => {
        return "\x1b[" + spaces + "C";
    },
/**
 * Returns a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 * Using Math.round() will give you a non-uniform distribution!
 */
    getRandomInt: (min, max) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    },
}

module.exports.isNumeric = function( str ) {
    if( typeof str === 'number' ) return true;
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
           !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

module.exports.isValidOrdinal = function( ordinal ) {
    if(ordinal > 0) return true;
    return false;
}

module.exports.InvalidDateException = function( message ) {
    this.message = message;
    this.name = 'InvalidDateException';
}

module.exports.TEMPERATE = "temperate";
module.exports.TROPICAL = "tropical";