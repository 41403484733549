import { useParams, useNavigate, Link } from "react-router-dom";
import { getUser, getToken } from "../service/AuthService";
import { useState, useEffect } from "react";
import { API_URL, API_KEY } from "../config/EndleConfig";
import axios from 'axios';
import TreasureList from "../components/TreasureList";
import Coins from "../components/Coins";
import ManifestList from "../components/ManfiestList";
import { enlargingImg, customLink } from "../service/MarkdownService";
import ReactMarkdown from 'react-markdown';

const Campaign = () => {
    const {resource, name} = useParams();
    const user = getUser();
    const token = getToken();

    const [isPending, setIsPending] = useState(false);
    const [formError, setFormError] = useState(false);
    const [campaign, setCampaign] = useState();
    const [message, setMessage] = useState();
    const request_url = API_URL + '/' + resource + '/' + name;

    const postChange = (url, body) => {
        setIsPending(true);
        setFormError(false);

        const request_config = {headers: { 'x-api-key':API_KEY }};
        axios.post(url, body, request_config).then( response => {
            setMessage(response.message);
            setIsPending(false);
        }).catch(error => {
            setIsPending(false);
            if(error.response.status === 401 || error.response.status === 403) {
                setMessage(error.response.data.message);
            } else {
                setMessage(("sorry ... our offices are busy.  please try again later."));
            }
        });
    }

    const coinUpdater = {
        updateTreasure: (updated) => {
            setCampaign({...campaign, treasure:{...campaign.treasure, coins:updated}});
        },

        submit: () => {
            const url = request_url + "/treasure/coins";
            const body = {
                dnd: {
                    name: campaign.name, 
                    status:campaign.status, 
                    treasure: {coins: campaign.treasure.coins}
                },
                user: { token: token, username: user.username }
            };
            postChange(url, body);
        }
    }

    const treasureUpdater = {
        updateTreasure: (treasure) => {
            setCampaign({ ...campaign, treasure: { ...campaign.treasure, pieces:treasure }});
        },

        submit: () => {
            const url = request_url + "/treasure/pieces";
            const body = {
                dnd: {
                    name: campaign.name, 
                    status:campaign.status, 
                    treasure: {pieces: campaign.treasure.pieces}
                },
                user: { token: token, username: user.username }
            };
            postChange(url, body);
        }
    };

    let navigate = useNavigate();

    useEffect(() => {
        const request_config = {
            headers: { 'x-api-key':API_KEY },
            params: { 'username': user.username, 'token':token }
        };
        setIsPending(true);
        axios.get(request_url, request_config).then( response => {
            setIsPending(false);
            setCampaign(response.data);
        }).catch( error => {
            setIsPending(false);
            if(error.response.status === 401 || error.response.status === 403) {
                if(error.response.data.message === 'invalid token') {
                    navigate('/login');
                }
                setMessage(error.response.data.message);
            } else {
                setMessage("something bad happened at the office and we're working to fix it.  please try again later.");
            }
        });
    }, [name]);

    return ( 
        <div className="campaign">
            {isPending && <div className="loading">LOADING ...</div> }
            {message && <p className="message">{message}</p> }
            {campaign && (
                <div>
                    <h1 className="with-summary">{campaign.name}</h1>
                    <p>{campaign.summary}</p>
                    <Coins coins={campaign.treasure.coins} user={user} handler={coinUpdater} />
                    <TreasureList treasure={campaign.treasure.pieces} user={user} handler={treasureUpdater} />
                    <ManifestList user={user} token={token} name={name} />
                    <section>
                        <ReactMarkdown 
                            components={{a: customLink, img:enlargingImg }}>
                                {campaign.body}
                        </ReactMarkdown>
                    </section>
                </div>
            )}
        </div>
    );
}
 
export default Campaign;