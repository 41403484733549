import { Link } from 'react-router-dom';
import ImageBox from '../components/ImageBox';

export function customLink({ as, href, ...otherProps }) {
    return (
        <Link as={as} to={href} {...otherProps}></Link>
    )
}

// TODO create a custom image componenet
export function enlargingImg({ as, src, ...otherProps}) {
    return (
        <ImageBox as={as} src={src} {...otherProps} />
    )
 }