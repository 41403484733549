import { Fragment } from "react";

const Attributes = ({attributes}) => {
    return (
        <section>
            <div className="two-column-grid grid">
                {attributes && attributes.map((attr, index) => (
                    <Fragment key={index}>
                        <div className="key">{attr.name}</div>
                        <div>{attr.value}</div>
                    </Fragment>
                ))} 
            </div>
        </section>
     );
}
 
export default Attributes;