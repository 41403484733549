import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useEffect, useState } from "react";
import { getToken, getUser, resetUserSession, setUserSession } from "./service/AuthService";
import { API_KEY, VERIFY_PATH, API_URL } from "./config/EndleConfig";
import axios from "axios";
import { GLYPH_CLIENT_PATH } from "./config/EndleConfig";

import MemberRoute from "./routes/MemberRoute";
import FellaRoute from "./routes/FellaRoute";
import PublicRoute from "./routes/PublicRoute";
import HomeRoute from "./routes/HomeRoute";
import GlyphRoute from "./routes/GlyphRoute";
import DMRoute from "./routes/DMRoute";

import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import Register from "./pages/Register";
import Ledger from "./pages/Ledger";
import Validate from "./pages/Validate";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Entry from "./pages/Entry";
import Entries from "./pages/Entries";
import Atlas from "./pages/Atlas";
import ViewImage from "./pages/ViewImage";
import Campaign from "./pages/Campaign";
import Adventure from "./pages/Adventure";
import Manifest from "./pages/manifests/Manifest";
import AddManifest from "./pages/manifests/AddManifest";


function App() {
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  useEffect(() => {
    const token = getToken();
    if(token === 'undefined' || token === undefined || token === null || !token) {
      return;
    }
  
    const verify_path = API_URL + VERIFY_PATH;
    const request_config = { headers: { 'x-api-key': API_KEY } };
    const request_body = { user: getUser(), token: token };
  
    axios.post(verify_path, request_body, request_config).then( response => {
      setUserSession(response.data.user, response.data.token);
      setIsAuthenticating(false);
    }).catch(() => {
      resetUserSession();
      setIsAuthenticating(false);
    });
  },[]);

  const token = getToken();
  if(isAuthenticating && token) {
    return (
      <>
        <div className="navbar">
          <div className='logo'>Endleland</div> 
        </div>
        <div className="App">
            <div className="content">
                Authenticating ...
            </div>
        </div>
    </>
    )
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={ <HomeRoute /> }/>
        
        <Route element={<PublicRoute />}>
          <Route exact path="/login" element={ <Login /> }/>
          <Route exact path="/register" element={ <Register /> }/>
          <Route exact path="/validate/:username/:token" element={ <Validate /> }/>
          <Route exact path="/forgot-password" element={ <ForgotPassword /> }/>
          <Route exact path="/reset-password/:username/:token" element={ <ResetPassword /> }/>
          <Route path="*" element={<NotFound />} />
        </Route>
        
        <Route element={<MemberRoute />}>
          <Route path="ledger" element={<Ledger />}/>
          <Route path="atlas" element={<Atlas />}/>
          <Route path="atlas/:resource" element={<Entries />}/>
          <Route path="atlas/:resource/:name" element={<Entry />}/>
        </Route>
  
        <Route element={<GlyphRoute />}>
          <Route path={GLYPH_CLIENT_PATH} element={<ViewImage />}/>
        </Route>

        <Route element={<DMRoute />}>
          <Route path="/dnd/:resource/:name/manifests/new" element={<AddManifest/> }/>
        </Route>

        <Route element={<FellaRoute />}>
          <Route path="/dnd" element={<Adventure />}/>
          <Route path="/dnd/:resource/:name" element={<Campaign />}/>  
          <Route path="/dnd/:resource/:name/manifests/:ordinal" element={<Manifest />}/>
        </Route>
        
      </Routes>
    </Router>
  );
}

export default App;