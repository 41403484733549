/** API PATHS **/
export const API_URL = 'https://oz73nxtkhd.execute-api.us-east-1.amazonaws.com/prod';
export const API_KEY = 'tpyWQPO0lx4V6bACNf7Rm4eB29oLLy018aGOcPhi';
export const LOGIN_PATH = '/login';
export const REGISTER_PATH = '/register';
export const VERIFY_PATH = '/verify';
export const VALIDATE_PATH = '/validate';
export const NOTIFICATIONS_PATH = "/notifications";
export const FORGOT_PASSSWORD_PATH = "/forgot_password";

/** CLIENT PATHS **/
export const REALMS_CLIENT_PATH = '/atlas/realms';
export const CITIES_CLIENT_PATH = '/atlas/cities';
export const PEOPLE_CLIENT_PATH = '/atlas/people';
export const LEDGER_CLIENT_PATH = '/ledger';
export const CREATURES_CLIENT_PATH = '/atlas/creatures';
export const ITEMS_CLIENT_PATH = '/atlas/items';
export const PLACES_CLIENT_PATH = '/atlas/places';
export const GLYPH_CLIENT_PATH = '/artifact/glyph';

/** USER ROLES **/
export const MEMBER_ROLE = 0;
export const SUBSCRIBER_ROLE = 1;
export const FELLA_ROLE = 2;
export const EMPEROR_ROLE = 3;
export const DM_ROLE = 3;

/** VERSIONS **/
export const CLIENT_VERSION = "0.0.5";