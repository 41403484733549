import { Link, useNavigate } from 'react-router-dom';

const GlyphNav = (props) => {
    const navigate = useNavigate();

    return ( 
        <nav>
            <div className="navbar">
                <Link className='logo' to="/">Endleland</Link>
                <div className="push">
                    <div className="button orange-background" onClick={ () => { navigate(-1);} }>back</div>
                </div>
            </div>
        </nav>
     );
}
 
export default GlyphNav;