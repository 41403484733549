import { useNavigate, useParams } from "react-router-dom";
import { getToken, getUser } from "../../service/AuthService";
import { Fragment, useEffect, useState } from "react";
import { API_URL, API_KEY, DM_ROLE } from "../../config/EndleConfig";
import { calendars } from "endle";
import axios from "axios";
import ReactMarkdown from "react-markdown";
import { customLink, enlargingImg } from "../../service/MarkdownService";
import Attributes from "../../components/Attributes";
import DeleteBtn from "../../components/buttons/DeleteButton";
import EditBtn from "../../components/buttons/EditButton";
import ManifestForm from "../../components/ManifestForm";

const Manifest = () => {

    const {resource, name, ordinal} = useParams();
    const user = getUser();
    const token = getToken();

    const [isPending, setIsPending] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [manifest, setManifest] = useState();
    const [message, setMessage] = useState();
    const quaanish = calendars.getQuaanishDate(parseInt(ordinal));
    

    const navigate = useNavigate();
    const request_url = API_URL + '/' + resource + '/' + name + '/manifests/' + ordinal;
    const request_config = {
        headers: { 'x-api-key':API_KEY },
        params: { 'username': user.username, 'token':token }
    };

    useEffect(() => {
        setIsPending(true);
        axios.get(request_url, request_config).then( response => {
            setIsPending(false);
            setManifest(response.data);
        }).catch( error => {
            setIsPending(false);
            if(error.response.status === 401 || error.response.status === 403) {
                if(error.response.data.message === 'invalid token') {
                    navigate('/login');
                }
                setMessage(error.response.data.message);
            } else {
                setMessage("something bad happened at the office and we're working to fix it.  please try again later.");
            }
        });
    }, [ordinal]);

    const deleteManifest = () => {
        console.log("delete");
        setIsPending(true);
        axios.delete(request_url, request_config).then(response => {
            navigate(`/dnd/campaigns/${manifest.name}`);
        }).catch(error => {
            setIsPending(false);
            if(error.response.status === 401 || error.response.status === 403) {
                if(error.response.data.message === 'invalid token') {
                    navigate('/login');
                }
                setMessage(error.response.data.message);
            } else {
                setMessage("something bad happened at the office and we're working to fix it.  please try again later.");
            }
        });
    }

    const editManifest = () => {
        console.log("edit");
        const original_ordinal = manifest.ordinal;
        setManifest({...manifest, original_ordinal:original_ordinal});
        setShowEdit(true);
    }

    const saveManifest = () => {
        setIsPending(true);
        const config = {headers:{'x-api-key':API_KEY}};
        const success_path = `/dnd/campaigns/${manifest.name}/manifests/${manifest.ordinal}`;
        const url = API_URL + '/campaigns/' + manifest.name + '/manifests/' + manifest.ordinal;
        
        const body = {
            manifest:manifest,
            user: { username:user.username, token:token }
        } 
        axios.post(url, body, config).then( response => {
            navigate(success_path);
            setShowEdit(false);
        }).catch(error => {
            if(error.response.status === 401 || error.response.status === 403) {
                setMessage(error.response.data.message);
                setIsPending(false);
            } else {
                setMessage(("sorry ... our offices are busy.  please try again later."));
            }
        });
    }

    return (
        <Fragment>
            {isPending && <div className="loading">LOADING ...</div> }
            {message && <p className="message">{message}</p> }
            <div className="manifest">
                {!showEdit && manifest && (
                    <Fragment>
                        <div className="header-flex">
                            <h1 className="with-summary">{manifest.summary}</h1>
                            { user.role === DM_ROLE && <Fragment>
                                <EditBtn handler={editManifest} />
                                <DeleteBtn handler={deleteManifest} />
                                </Fragment> }
                        </div>
                        
                        <p>{quaanish.short_date}</p>
                        <Attributes attributes={manifest.attributes} />
                        <ReactMarkdown
                            components={{a: customLink, img:enlargingImg }}
                            className="manifest-body">
                                {manifest.body}
                        </ReactMarkdown>
                        
                    </Fragment>
                )}
                {showEdit && manifest && (
                    <Fragment>
                        <h1>Edit Manifest</h1>
                        <ManifestForm manifest={manifest} setManifest={setManifest} handler={saveManifest} />
                    </Fragment>
                    
                )}
            </div> 
        </Fragment>
        
    );
}
 
export default Manifest;