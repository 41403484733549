import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { LEDGER_CLIENT_PATH } from "../config/EndleConfig";
import { resetUserSession } from "../service/AuthService";
import useOutsideClick from "../hooks/useOutsideClick";

const AccountMenu = () => {
    
    let navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const handleOpen = () => { setOpen(!open); }

    const handleMenuClick = (path) => {
        setOpen(false);
        navigate(path);
    }

    const handleLogout = () => {
        setOpen(false);
        resetUserSession();
        navigate('/login');
    }
    const handleOutsideClick = () => { setOpen(false); }
    const ref = useOutsideClick(handleOutsideClick);

    return ( 
        <div className="dropdown button">
            <div ref={ref} onClick={handleOpen}>Account</div>
            {open ? (<ul className="menu">
                <li><button key={LEDGER_CLIENT_PATH} onClick={()=>{ handleMenuClick(LEDGER_CLIENT_PATH); }}>Settings</button></li>
                <li><button key={'logout'} onClick={handleLogout}>Logout</button></li>
            </ul>) : null}
        </div>
     );
}
 
export default AccountMenu;