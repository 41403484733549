const { isValidOrdinal, InvalidDateException, getRandomInt, TEMPERATE } = require( './utils');

// month name constants
const ULSILDANE = "Ulsildane";   const ISILDANE = "Isildane";   const ILMANDANE = "Ilmandane";
const BREZDUNE = "Brezdune";     const TILSDANE = "Tilsdane";

// day of week name constants
const INOMUN = "Inomun";   const ANIMUN = "Animun";    const BALMUN = "Balmun";
const ROTIMUN = "Rotimun"; const UMON = "Umon";

const THREE_TRIBE_ZERO_DAY = 1251281;
const THREE_TRIBE_YEAR_OFFSET = 3430;
const THREE_TRIBE_YEAR_LENGTH = 365;
const THREE_TRIBE_DAY_OFFSET = 304;

const three_tribe_months = [
   {name:  ULSILDANE,  start_day: 1,     index: 1,   length: 90 },
   {name:  ISILDANE,   start_day: 91,    index: 2,   length: 90 },
   {name:  ILMANDANE,  start_day: 181,   index: 3,   length: 90 },
   {name:  BREZDUNE,   start_day: 271,   index: 4,   length: 5 },
   {name:  TILSDANE,   start_day: 276,   index: 5,   length: 90 },
];

const three_tribe_days = [
    {name: INOMUN,    abbr: "IN"},
    {name: ANIMUN,    abbr: "AN"},
    {name: BALMUN,    abbr: "BN"},
    {name: ROTIMUN,    abbr: "RN"},
    {name: UMON,    abbr: "UN"},
];

function getMonth( month_name ) {
    const month = three_tribe_months.find( ({ name }) => name == month_name );
    if( month === undefined) throw new InvalidDateException( "invalid month" );
    else return month;
}

function validDate( month_name, day_of_month) {
    const m = getMonth(month_name);
    if(day_of_month > 0 && day_of_month <= m.length) { return true; }
    else throw new InvalidDateException("invalid day of month");
}

const zone = TEMPERATE;

module.exports = {
    zone: zone,

    isMonth: function (month_name) {
        try {
            const m = getMonth(month_name);
            return true;
        }
        catch(e) {
            return false;
        }
    },
    
    getMonthFromYearDay: function(year_day) {
        for (i = 0; i < three_tribe_months.length; i++) {
            var month = three_tribe_months[i];
            var date = {};
            if (year_day < (month.start_day + month.length)) {
                date.month = month;
                date.month_day = year_day - month.start_day + 1;
                return date;
            }
        }
    },
    
    getOrdinal:
      function(day_of_month, month_name, year) {
        var ordinal_day;
        try {
            const m = getMonth(month_name);
            if (validDate(month_name, day_of_month)) {
                ordinal_day = m.start_day - 1 + day_of_month + (year * 365) + THREE_TRIBE_ZERO_DAY;
                if (year > 0) ordinal_day = ordinal_day - 365; // we don't have a year year, so subract that form the total.
                return ordinal_day;
            }
        }
        catch(e) { throw e; }
    },

    getDOW: function (ordinal_day) { return three_tribe_days[(ordinal_day) % three_tribe_days.length].name; },
    
    getDate: (ordinal_day) => {
        var three_tribe_ordinal, year, year_day, month_date, dow;

        function getYear() {
            // there is no '0' year in the Quaanish calendar so we add one to the year to skip zero
            year = Math.floor((three_tribe_ordinal - 1) / 365) + 1 - THREE_TRIBE_YEAR_OFFSET;
            if (ordinal_day > THREE_TRIBE_ZERO_DAY) { year = year + 1; }

            year_day = three_tribe_ordinal - Math.floor((three_tribe_ordinal + 364) / 365 - 1) * 365;
        }

        if (isValidOrdinal(ordinal_day)) {
            three_tribe_ordinal = ordinal_day + THREE_TRIBE_DAY_OFFSET;
            getYear();
            month_date = module.exports.getMonthFromYearDay(year_day);
            dow = module.exports.getDOW(ordinal_day);
            return dow + ", " + month_date.month_day + " " + month_date.month.name + " " + year;
        }
        return false;
    },
    
    getMonths: () => {
        var months = [];
        for (i = 0; i < three_tribe_months.length; i++) {
            months.push({ name: three_tribe_months[i].name, days: three_tribe_months[i].length, 
                start_day: three_tribe_months[i].start_day });
        }
        return months;
    },
   
    getRandomDateInYear: (year) => {
        var year_day = getRandomInt(1, THREE_TRIBE_YEAR_LENGTH);
        var month_date = module.exports.getMonthFromYearDay(year_day);
        var ordinal_day = module.exports.getOrdinal(month_date.month_day, month_date.month.name, year);
        return ordinal_day;
        // return module.exports.getDate(ordinal_day);
    }
  }