import { useState } from "react";
import axios from "axios";
import { setUserSession } from "../service/AuthService";
import { API_KEY, API_URL, LOGIN_PATH } from "../config/EndleConfig";
import { Link, useNavigate } from "react-router-dom";

const Login = () => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState();
    const [isPending, setIsPending] = useState(false);
    let navigate = useNavigate();

    const submitHandler = (event) => {
        event.preventDefault();
        if(username.trim() === '' || password.trim() ==='') {
            setMessage('All fields are required');
        }
        else if(username.length <3 || username.length > 24) { setMessage('valid usernames are between 3 and 24 characters'); }
        else if(password.length <8 || password.length > 32) { setMessage('valid passwords are between 8 and 32 characters'); }
        else {
            setIsPending(true);
            const login_url = API_URL + LOGIN_PATH;
            const request_config = {headers: { 'x-api-key':API_KEY }};
            const request_body = {username: username, password: password};
            axios.post(login_url, request_body, request_config).then( response => {
                setUserSession(response.data.user, response.data.token);
                navigate('/');
            }).catch(error => {
                setIsPending(false);
                if(error.response.status === 401 || error.response.status === 403) {
                    setMessage(error.response.data.message);
                } else {
                    setMessage("sorry ... our offices are busy.  please try again later.");
                }
            });
        }
    }

    return ( 
        <div className="login">
            <form onSubmit={submitHandler} autoComplete="on">
                <h2>LOGIN</h2>
                <section>
                    <div className="two-column-grid med-2-grid grid">
                        <div>username:</div>
                        <div>
                            <input name="username" autoComplete="off" type="text" value={username} onChange={ 
                            (event) => {
                                setMessage('');
                                setUsername(event.target.value);
                            }} />
                        </div>
                    

                    
                        <div>password:</div>
                        <div>
                            <input name="password" type="password" value={password} onChange={
                            (event) => { 
                                setMessage('');
                                setPassword(event.target.value);
                            }} />
                        </div>
                    </div>
                    {isPending && <div className="loading">LOADING ...</div> }
                    {!isPending && <div className="button orange-background" onClick={ submitHandler }>login</div>}
                    <p className="message">{ message }</p>
                </section>
                

            </form>
            <p className="small-caps"><Link to="/forgot-password">forgot your password?</Link></p>
        </div>
     );
}
 
export default Login;